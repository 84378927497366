import { graphql, PageProps } from "gatsby"
import _ from "lodash"
import React from "react"

import { CollectionPageContext } from "../interfaces/pageContext"
import CollectionPage from "./CollectionPage"

export type SalePageProps = PageProps<any, CollectionPageContext>

const SalePage: React.FC<SalePageProps> = (props) => {
  const { data } = props
  const navigation = [
    {
      heading: `Sale Marken`,
      links: _.sortBy(
        data.brandSalePages.edges.map(({ node }: any) => ({
          to: node.path,
          text: `${node.context.brand} Sale`,
        })),
        [(link): string => link.text.toLowerCase()]
      ),
    },
    {
      heading: `Sale Kategorien`,
      links: _.sortBy(
        data.categorySalePages.edges.map(({ node }: any) => ({
          to: node.path,
          text: `${node.context.category} Sale`,
        })),
        [(link): string => link.text.toLowerCase()]
      ),
    },
  ]
  return (
    <CollectionPage
      name={`Sale`}
      description={`Taschen im Sale online kaufen bei buybags.de. Entdecke jetzt die besten Angebote an reduzierten Designer Taschen. Hier Handtaschen, Umhängetaschen und Shopper für Damen günstig online kaufen.`}
      primaryKeyword={`Designer Taschen Sale`}
      secondaryKeyword={`Spare bis zu 50%`}
      navigation={navigation}
      {...props}
    />
  )
}

export default SalePage

export const query = graphql`
  query SalePageQuery($skip: Int!, $limit: Int!) {
    allProductsCsv(filter: { fields: { sale: { eq: true } } }, skip: $skip, limit: $limit) {
      edges {
        node {
          fields {
            brand
            colors
            deepLink
            deliveryCost
            deliveryTime
            description
            gtin
            images {
              alt
              src
              title
            }
            material
            name
            price
            priceBefore
            sale
            slug
          }
        }
      }
      totalCount
    }
    brandSalePages: allSitePage(
      filter: {
        context: {
          humanPageNumber: { eq: 1 }
          numberOfPages: { gt: 1 }
          type: { eq: "BrandSalePage" }
        }
      }
      sort: { fields: [context___brand], order: ASC }
    ) {
      edges {
        node {
          path
          context {
            brand
          }
        }
      }
    }
    categorySalePages: allSitePage(
      filter: { context: { humanPageNumber: { eq: 1 }, type: { eq: "CategorySalePage" } } }
      sort: { fields: [context___category], order: ASC }
    ) {
      edges {
        node {
          path
          context {
            category
          }
        }
      }
    }
  }
`
